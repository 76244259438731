const getConfigObj = () => {
  // Find the environment by the subdomain
  const env = window.location.host.split(".")[0];
  switch (env) {
    case "dev":
      return {
        API: {
          baseURL: "https://dev-api.drsplatform.net/"
        }
      };
    case "uat":
      return {
        API: {
          baseURL: "https://uat-api.drsplatform.net/"
        }
      };
    case "driverprovider":
      return {
        API: {
          baseURL: "https://driverprovider-api.drsplatform.net/"
        }
      };
    case "moredrivers":
      return {
        API: {
          baseURL: "https://moredrivers-api.drsplatform.net/"
        }
      };
    case "wprg":
      return {
        API: {
          baseURL: "https://wprg-api.drsplatform.net/"
        }
      };
    case "advance":
      return {
        API: {
          baseURL: "https://advance-api.drsplatform.net/"
        }
      };
    case "pps":
      return {
        API: {
          baseURL: "https://pps-api.drsplatform.net/"
        }
      };
    case "driversrelief":
      return {
        API: {
          baseURL: "https://driversrelief-api.drsplatform.net/"
        }
      };
    case "wde":
      return {
        API: {
          baseURL: "https://wde-api.drsplatform.net/"
        }
      };
    case "phoenix":
      return {
        API: {
          baseURL: "https://phoenix-api.drsplatform.net/"
        }
      };
    case "phoenix2":
      return {
        API: {
          baseURL: "https://phoenix-api2.drsplatform.net/"
        }
      };
    case "phoenix3":
      return {
        API: {
          baseURL: "https://phoenix-api3.drsplatform.net/"
        }
      };
    case "demo":
      return {
        API: {
          baseURL: "https://demo-api.drsplatform.net/"
        }
      };
    case "eruk2":
      return {
        API: {
          baseURL: "https://eruk-api2.drsplatform.net/"
        }
      };
    case "grafters":
      return {
        API: {
          baseURL: "https://grafters-api.drsplatform.net/"
        }
      };
    case "drive":
      return {
        API: {
          baseURL: "https://drive-api.drsplatform.net/"
        }
      };
    case "hgms":
      return {
        API: {
          baseURL: "https://hgms-api.drsplatform.net/"
        }
      };
    case "quest":
      return {
        API: {
          baseURL: "https://quest-api.drsplatform.net/"
        }
      };
    case "quest2":
      return {
        API: {
          baseURL: "https://quest-api2.drsplatform.net/"
        }
      };
    case "eruk":
      return {
        API: {
          baseURL: "https://eruk-api.drsplatform.net/"
        }
      };
    case "hgms2":
      return {
        API: {
          baseURL: "https://hgms2-api.drsplatform.net/"
        }
      };
    case "workforce":
      return {
        API: {
          baseURL: "https://workforce-api.drsplatform.net/"
        }
      };
    case "workforce2":
      return {
        API: {
          baseURL: "https://workforce-api2.drsplatform.net/"
        }
      };
    case "sdr":
      return {
        API: {
          baseURL: "https://sdr-api.drsplatform.net/"
        }
      };
    default:
      return {
        API: {
          baseURL: "https://uat-api.drsplatform.net/"
        }
      };
  }
};

const config = getConfigObj();

export default config;
