import React from "react";
import { Navigate } from "react-router-dom";
import { Layout } from "antd";
import Auth from "../config/auth";
import ErrorBoundaries from "../modules/errors/errorBoundaries";
import Loader from "../components/pageLoader";
import { Header, SubHeader, Footer, ApiMessage } from "./modulesPath";

import "../assets/styles/layout.scss";

const { Content } = Layout;

const SecuredRoute = ({ roles, component: Component }) => {
  const isAuthenticated = Auth.isAuthenticated();
  const userRoles = Auth.getSessionDetails()?.userRoles.map(({ RoleName }) => RoleName);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  
  const hasAccess = roles.some(role => userRoles.includes(role));

  if (!hasAccess) {
    return <Navigate to="/no-auth" />;
  }

  return (
    <ErrorBoundaries>
      <React.Suspense fallback={<Loader />}>
        <Layout className="drs-layout">
          <Header />
          <Content className="drs-layout-content">
            <ApiMessage />
            <SubHeader />
            <div className="drs-layout-component">
              <Component />
            </div>
          </Content>
          <Footer />
        </Layout>
      </React.Suspense>
    </ErrorBoundaries>
  );
};

export default SecuredRoute;
