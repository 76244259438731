import action from "../actionTypes/filterTypes";

const initialState = {
  selectedStatus: [],
  searchByKeyword: "",
  selectedStartDate: null,
  selectedEndDate: null,
  selectedBranches: []
};

const financeFilterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case action.FINANCE_SELECTED_START_DATE:
      return { ...state, selectedStartDate: payload };

    case action.FINANCE_SELECTED_END_DATE:
      return { ...state, selectedEndDate: payload };

    case action.FINANCE_SEARCHED_KEYWORD:
      return { ...state, searchByKeyword: payload };

    case action.FINANCE_SELECTED_BRANCHES:
      return { ...state, selectedBranches: [...payload] };

    case action.FINANCE_SELECTED_STATUS:
      return { ...state, selectedStatus: [...payload] };

    default:
      return state;
  }
};

export default financeFilterReducer;
