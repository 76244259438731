import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

import { Row, Col, Form, Input, Button, Spin } from "antd";
import AlertMessage from "../../components/authBg/alert";
import { UsernameOrEmailValidation } from "../../constants/promptRules";
import URL from "../../config/envUrls";
import _labels from "../../constants/labels";
import _messages from "../../constants/messages";

import LoaderIcon from "../../components/loader";
import AuthLogo from "../../assets/images/png/drsNewPlain.png";
import "./reset-password.scss";
import { useRef } from "react";

const {
  LOGIN: { REQUEST_RESET_PASSWORD_END_POINT }
} = _labels;

const { REQUEST_RESET_PASSWORD_ERROR } = _messages;

const LoginHeader = () => (
  <div className="auth-logo-wrapper">
    <div className="auth-logo-img">
      <img src={AuthLogo} alt="auth-logo-img" />
    </div>
    <h2 className="login-header-text">Request Reset Password</h2>
  </div>
);

const LoginComponent = () => {
  const formRef = useRef();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState(null);

  const onFormSubmit = values => {
    setLoading(true);
    const { usernameEmail } = values;

    const TargetResetURL = `${window.location.origin}/completepwreset?id=`;
    axios
      .post(
        `${URL.API.baseURL}${REQUEST_RESET_PASSWORD_END_POINT}?usernameOrEmail=${usernameEmail}&targetResetUrl=${TargetResetURL}`
      )
      .then(responseData => {
        if (responseData.status === 200) {
          if (responseData.data.Response === "Success") {
            setAlertType("success");
            setMessage(responseData.data.Message);
            formRef?.current?.setFieldsValue({
              usernameEmail: ""
            });
          }
          if (responseData.data.Response === "Failure") {
            setAlertType("error");
            setMessage(responseData.data.ErrorMessage);
          }
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setMessage(REQUEST_RESET_PASSWORD_ERROR);
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(location);
    if (location.search.includes("username")) {
      formRef?.current?.setFieldsValue({
        usernameEmail: location.search.split("=")[1]
      });
    }
  }, []);

  return (
    <div className="reset-password-bg">
      <Spin
        indicator={<LoaderIcon />}
        spinning={loading}
        className="loaderGifPosition reset-password-wrapper"
      >
        <Row className="login-wrapper">
          <Col>
            <LoginHeader />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={11} className="login-auth-area">
            <Col className="login-auth-wrapper">
              <AlertMessage type={alertType} msg={message} />
              <Form className="login-form" onFinish={onFormSubmit} ref={formRef}>
                <Form.Item
                  name="usernameEmail"
                  rules={UsernameOrEmailValidation}
                  className="emailInput"
                >
                  <Input placeholder="Please enter username or email" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Submit
                  </Button>
                </Form.Item>

                <Col className="request-pass-home-link">
                  <Link type="primary" to="/login" className="login-form-button">
                    Home
                  </Link>
                </Col>
              </Form>
            </Col>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default React.memo(LoginComponent);
