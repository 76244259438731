import React from "react";
import { Route, Navigate, BrowserRouter, Routes } from "react-router-dom";
import Auth from "../config/auth";
import Login from "../modules/login";
import ForgetPassword from "../modules/forgotPassword";
import { routeArray } from "./routesGuard";
import NoAuth from "../modules/errors/401";
import NotFound from "../modules/errors/404";
import constants from "../constants/constants";
import RequestPasswordReset from "../modules/resetPassword/RequestPasswordReset";
import CompletePasswordReset from "../modules/resetPassword/CompletePasswordReset";
import generatePrivateRoutes from "./routesGuard";

const {
  PAGE_ROUTES: {
    LOGIN,
    FORGET_PASSWORD,
    NOT_FOUND,
    NOT_AUTH,
    REQUEST_PASSWORD_RESET,
    COMPLETE_PASSWORD_RESET
  }
} = constants;

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={LOGIN} element={<Login />} />
        <Route path={FORGET_PASSWORD} element={<ForgetPassword />} />
        <Route path={REQUEST_PASSWORD_RESET} element={<RequestPasswordReset />} />
        <Route path={COMPLETE_PASSWORD_RESET} element={<CompletePasswordReset />} />
        <Route path={NOT_AUTH} element={<NoAuth />} />
        <Route path={NOT_FOUND} element={<NotFound />} />
        <Route path="*" element={!Auth.isAuthenticated() && <Navigate to="/login" />} />
        {generatePrivateRoutes(routeArray)}
      </Routes>
    </BrowserRouter>
  );
}

export default React.memo(App);
