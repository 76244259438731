import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { LockOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, Spin } from "antd";
import AlertMessage from "../../components/authBg/alert";
import Auth from "../../config/auth";
import URL from "../../config/envUrls";
import _constants from "../../constants/constants";
import _labels from "../../constants/labels";
import _messages from "../../constants/messages";
import { PasswordRule, UserNameValidation } from "../../constants/promptRules";

import AuthLogo from "../../assets/images/png/drsNewPlain.png";
import LoaderIcon from "../../components/loader";
import getDefaultRoute from "../../utils/getDefaultRoute";
import "./login.scss";

const {
  LOGIN: {
    HEADER_LABEL,
    PASSWORD_LABEL,
    USERNAME_LABEL,
    REMEMBER_LABEL,
    BUTTON_LABEL,
    SECURE_LABEL,
    API_END_POINT,
    GET_START_DAY_ENDPOINT,
    REM_ME
  },
  SUB_HEADER_MENU: {
    AGENCY_USER,
    AGENCY_ADMIN,
    AGENCY_SUPER_OPERATOR,
    FINANCE_USER,
    CLIENT_USER,
    SYSTEM_ADMIN,
    WEBSITE_LINK
  }
} = _labels;

const {
  PAGE_ROUTES: { LOGIN, REQUEST_PASSWORD_RESET }
} = _constants;

const { LOGIN_ERROR } = _messages;

const LoginHeader = () => (
  <div className="auth-logo-wrapper">
    <div className="auth-logo-img">
      <img src={AuthLogo} alt="auth-logo-img" />
    </div>
    <h2 className="login-header-text">{HEADER_LABEL}</h2>
  </div>
);

const LoginFooter = () => (
  <div className="login-footer">
    <LockOutlined style={{ fontSize: 16, color: "#8692a6", marginTop: 3 }} />
    <h4>{SECURE_LABEL}</h4>
  </div>
);

const sortOrderList = [
  AGENCY_ADMIN,
  AGENCY_USER,
  FINANCE_USER,
  CLIENT_USER,
  SYSTEM_ADMIN,
  AGENCY_SUPER_OPERATOR,
  WEBSITE_LINK
];

const sortedObjList = data => {
  const sortedList = data.sort((a, b) => {
    return sortOrderList.indexOf(a?.RoleName) - sortOrderList.indexOf(b?.RoleName);
  });
  return sortedList || [];
};

const LoginComponent = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onFormSubmit = values => {
    setLoading(true);
    localStorage.clear();
    const { username, password, remember } = values;
    axios
      .post(`${URL.API.baseURL}${API_END_POINT}`, {
        username,
        password
      })
      .then(responseData => {
        if (responseData.status === 200) {
          const payload = {
            resultData: responseData.data
          };
          const rolesU = {
            userRoles: payload.resultData?.existingSystemRoles
          };
          localStorage.setItem("eUserRoles", JSON.stringify(rolesU));
          localStorage.setItem("loggedInUser", JSON.stringify(responseData.data));

          const { sessionKey, user = "", roles = "" } = responseData?.data || "";
          Auth.set(REM_ME, remember);
          Auth.setSessionKey(sessionKey);
          const { ObjectId: userObjectId, Username } = user;
          const userRoles = roles.map(({ RoleName, ObjectId }) => ({ RoleName, ObjectId }));
          const currentUserRole = sortedObjList(userRoles)[0]?.RoleName || null;
          const newObject = {
            userRoles,
            userObjectId,
            userName: Username,
            currentUserRole
          };
          Auth.storeSessionDetails(newObject);
          const navigateTo = getDefaultRoute(currentUserRole);
          setLoading(false);
          axios
            .get(`${URL.API.baseURL}${GET_START_DAY_ENDPOINT}`, {
              headers: {
                sessionKey
              }
            })
            .then(apiresponse => {
              localStorage.setItem(
                "day",
                JSON.stringify(apiresponse?.data?.PlatformDefaultFirstDayOfWeek)
              );
              window.location.replace(navigateTo);
            });
        }
      })
      .catch(() => {
        setLoading(false);
        setMessage(LOGIN_ERROR);
        navigate(LOGIN);
      });
  };

  useEffect(() => {
    const { currentUserRole } = Auth.getSessionDetails() || "";
    if (Auth.isAuthenticated() && currentUserRole) {
      const navigateTo = getDefaultRoute(currentUserRole);
      navigate(navigateTo);
    }
  }, []);

  const formProps = {
    name: "drs-login",
    className: "login-form",
    initialValues: {
      remember: false,
      email: ""
    },
    onFinish: onFormSubmit
  };

  return (
    <div className="login-bg">
      <Spin indicator={<LoaderIcon />} spinning={loading} className="loaderGifPosition">
        <Row className="login-wrapper">
          <Col>
            <LoginHeader />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={11} className="login-auth-area">
            <Col className="login-auth-wrapper">
              <AlertMessage msg={message} />
              <Form {...formProps}>
                <h4>{USERNAME_LABEL}</h4>
                <Form.Item name="username" rules={UserNameValidation} className="emailInput">
                  <Input placeholder="Please enter username" />
                </Form.Item>
                <h4>{PASSWORD_LABEL}</h4>
                <Form.Item name="password" rules={[PasswordRule]} className="passwordInput">
                  <Input.Password placeholder="Please enter password" />
                </Form.Item>
                <Form.Item>
                  <Form.Item name="remember" noStyle valuePropName="checked">
                    <Checkbox>
                      <span className="rememberLabel">{REMEMBER_LABEL}</span>
                    </Checkbox>
                    <Link to={REQUEST_PASSWORD_RESET}>reset password</Link>
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    {BUTTON_LABEL}
                  </Button>
                </Form.Item>
              </Form>
              <LoginFooter />
            </Col>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default React.memo(LoginComponent);
