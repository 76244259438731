import React from "react";

import { Spin } from "antd";
import PageLoader from "../loader";

const RouteLoader = ({
  style = {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}) => {
  return (
    <div style={style}>
      <Spin indicator={<PageLoader />} />
    </div>
  );
};

export default React.memo(RouteLoader);
